import React from 'react';

import getFormatedDate from '@functions/getFormatedDate.ts';
import getUserName from '@functions/getUserName.ts';
import CallT from '@global/models/Call.ts';
import SchemaFileT from '@global/schemas/File.ts';

import ChatFile from '../../../../../views/crm/chat/components/window/components/file/File.tsx';

import { RenderColsT } from '../../types.ts';

const renderCalls: RenderColsT<'calls'> = function ({ name: colName, item }) {
    let content;
    let className;
    const call = item as CallT;

    if (colName === 'type') {
        content = <div className="v2table__text">{call.typeText}</div>;
    }

    if (colName === 'from') {
        content =
            call.type === 'outcoming' ? (
                <div className="v2table__text">
                    {call.user ? getUserName({ user: call.user, type: 'double' }) : '–'}
                </div>
            ) : (
                <div className="v2table__text">{call.executorPhone}</div>
            );
    }

    if (colName === 'to') {
        content =
            call.type === 'incoming' ? (
                <div className="v2table__text">
                    {call.user ? getUserName({ user: call.user, type: 'double' }) : '–'}
                </div>
            ) : (
                <div className="v2table__text">{call.executorPhone}</div>
            );
    }

    if (colName === 'status') {
        let statusClassName = '_wait';

        if (call.status === 'completed') {
            statusClassName = '_success';
        }

        if (call.status === 'reject') {
            statusClassName = '_alert';
        }

        content = <div className={`v2table__status ${statusClassName}`}>{call.statusText}</div>;
    }

    if (colName === 'record') {
        className = '_full';

        content = call.recordFullSrc ? (
            <>
                <div className="v2table__audio">
                    <ChatFile
                        file={
                            {
                                duration: call.recordDuration!,
                                path: call.recordSrc!,
                                fullSrc: call.recordFullSrc,
                            } as SchemaFileT & { fullSrc?: string }
                        }
                    />
                </div>
            </>
        ) : (
            <>
                <div className="v2table__text">–</div>
            </>
        );
    }

    if (colName === 'date') {
        content = (
            <div className="v2table__text">
                {getFormatedDate({
                    date: new Date(call.createdDate),
                    type: 'full',
                    isShortYear: true,
                })}
            </div>
        );
    }

    return { content, className };
};

export default renderCalls;
