import alertTask from './notifications/alertTask';
import chatResponsible from './notifications/chatResponsible';
import disabledSignService from './notifications/disabledSignService';
import executorJoin from './notifications/executorJoin';
import newDeal from './notifications/newDeal';
import newMessage from './notifications/newMessage';
import newTask from './notifications/newTask';

const notifications = {
    executorJoin,
    newMessage,
    chatResponsible,
    newTask,
    alertTask,
    newDeal,
    disabledSignService,
} as const;

export default notifications;
