import React from 'react';

import Button from '@components/button/Button.tsx';
import Error from '@components/error/Error.tsx';
import LoaderBlock from '@components/loaderBlock/LoaderBlock.tsx';

import I, { ContentT } from '../types.ts';

const renderFoot: I['renderFoot'] = function () {
    const { type, error, loadingKey } = this.state;
    const content = type ? (this.contents[type] as ContentT) : null;
    const otherCancel = content?.otherCancel;
    const renderOther = content?.renderOther;
    const renderAfter = content?.renderAfter;

    return (
        <div className="v2popup__foot _COL _CENTER _top">
            {content?.mode === 'important' && (
                <>
                    <div className="v2popup__button">
                        <Button className="_main" onClick={this.close.bind(this)}>
                            {content?.buttonText}
                        </Button>
                    </div>
                    <div className="v2popup__cancel _CLICK" onClick={this.save.bind(this)}>
                        {content?.cancelText || 'Продолжить'}
                        <LoaderBlock
                            className="v2popup__cancelLoader _FULL"
                            isShow={loadingKey === 'save'}
                        />
                    </div>
                </>
            )}
            {content?.mode !== 'important' && (
                <>
                    {typeof renderOther === 'function' && renderOther.call(this)}
                    <div className="v2popup__button">
                        <Button
                            className="_main"
                            onClick={this.save.bind(this)}
                            loading={loadingKey === 'save'}
                        >
                            {content?.buttonText || 'Понятно!'}
                        </Button>
                    </div>

                    {content?.mode !== 'support' && (
                        <>
                            {typeof otherCancel === 'function' ? (
                                <>{otherCancel.call(this)}</>
                            ) : (
                                <>
                                    {content?.mode !== 'denied' && (
                                        <div
                                            className="v2popup__cancel _CLICK"
                                            onClick={this.close.bind(this)}
                                        >
                                            {content?.cancelText || 'Отменить'}
                                        </div>
                                    )}
                                </>
                            )}
                        </>
                    )}

                    {typeof renderAfter === 'function' && renderAfter.call(this)}
                </>
            )}

            <Error className="v2popup__error _center" error={error?.text} />
        </div>
    );
};

export default renderFoot;
