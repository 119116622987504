import checkAdminCorporation from '@functions/crm/checkAdminCorporation';
import getCurrentCorporation from '@functions/crm/getCurrentCorporation';
import getPermissions from '@functions/crm/getPermissions';
import { getCookie } from '@functions/handlerCookies';
import { PageGetCondT, PageGetRedirectT, StoreT } from '@global/types';

const crmPages = [
    {
        links: [],
        name: 'corporation',
        group: 'cabinet',
        getRedirect() {
            return {
                name: 'auth',
            };
        },
        getCond({ user }: StoreT<'crm'>): PageGetCondT {
            return {
                condition: !!(user || getCookie(process.env.REACT_APP_HASH)),
            };
        },
    },
    {
        links: [undefined, ''],
        name: 'corporation-index',
        level: 1,
        parentName: 'corporation',
    },
    {
        links: ['corporations'],
        name: 'corporations',
        level: 1,
        parentName: 'corporation',
        contentOfLink: 'Компании',
        getCond({ user }: StoreT<'crm'>): PageGetCondT {
            return {
                condition: !!(
                    checkAdminCorporation({ user }) &&
                    getPermissions(user, {
                        key: 'corporations',
                        items: [{ key: 'main', rules: ['read'] }],
                    })
                ),
            };
        },
    },
    {
        links: ['', undefined],
        name: 'corporations-index',
        level: 2,
        parentName: 'corporations',
    },
    {
        links: [],
        name: 'corporations-inner',
        level: 2,
        parentName: 'corporations',
    },
    {
        links: ['', undefined],
        name: 'corporations-inner-main',
        level: 3,
        parentName: 'corporations-inner',
        contentOfLink: 'Детали',
    },
    {
        links: ['operations'],
        name: 'corporations-inner-operations',
        level: 3,
        parentName: 'corporations-inner',
        contentOfLink: 'Операции',
    },
    {
        links: ['docs'],
        name: 'corporations-inner-docs',
        level: 3,
        parentName: 'corporations-inner',
        contentOfLink: 'Документы',
        getCond({ user }: StoreT<'crm'>): PageGetCondT {
            return {
                condition: getPermissions(user, {
                    key: 'corporations',
                    items: [{ key: 'main', rules: ['read'] }],
                }),
            };
        },
    },
    {
        links: ['users'],
        name: 'corporations-inner-users',
        level: 3,
        parentName: 'corporations-inner',
        contentOfLink: 'Сотрудники',
    },
    {
        links: ['logs'],
        name: 'corporations-inner-logs',
        level: 3,
        parentName: 'corporations-inner',
        contentOfLink: 'Логи',
        getCond({ user }: StoreT<'crm'>): PageGetCondT {
            return {
                condition: checkAdminCorporation({ user }),
            };
        },
    },
    {
        links: ['joins'],
        name: 'joins',
        contentOfLink: 'Подключение',
        level: 1,
        parentName: 'corporation',
        getRedirect({ user }: StoreT<'crm'>): PageGetRedirectT {
            if (!user) {
                return { name: 'auth' };
            }

            return { name: 'corporation' };
        },
        getCond({ user }: StoreT<'crm'>): PageGetCondT {
            return {
                condition: getPermissions(user, { key: 'joins' }),
            };
        },
    },
    {
        links: ['invites'],
        name: 'joins-invites',
        contentOfLink: 'Приглашения',
        level: 2,
        parentName: 'joins',
        getCond({ user }: StoreT<'crm'>): PageGetCondT {
            return {
                condition: getPermissions(user, {
                    key: 'joins',
                    items: [{ key: 'invites', rules: ['read'] }],
                }),
            };
        },
    },
    {
        links: ['scripts'],
        name: 'joins-scripts',
        contentOfLink: 'Сценарии',
        level: 2,
        parentName: 'joins',
        getCond({ user }: StoreT<'crm'>): PageGetCondT {
            return {
                condition: getPermissions(user, {
                    key: 'joins',
                    items: [{ key: 'scripts', rules: ['read'] }],
                }),
            };
        },
    },
    {
        links: ['main', '', undefined],
        name: 'joins-scripts-main',
        level: 3,
        parentName: 'joins-scripts',
    },
    {
        links: [],
        name: 'joins-scripts-inner',
        contentOfLink: '+ Новый сценарий',
        level: 3,
        parentName: 'joins-scripts',
    },
    {
        links: ['main', '', undefined],
        name: 'joins-scripts-inner-main',
        contentOfLink: 'Детали',
        level: 4,
        parentName: 'joins-scripts-inner',
    },
    {
        links: ['logs'],
        name: 'joins-scripts-inner-logs',
        contentOfLink: 'Логи',
        level: 4,
        parentName: 'joins-scripts-inner',
    },
    {
        links: ['templates'],
        name: 'joins-templates',
        contentOfLink: 'Шаблоны',
        level: 2,
        parentName: 'joins',
        getCond({ user }: StoreT<'crm'>): PageGetCondT {
            return {
                condition: getPermissions(user, {
                    key: 'joins',
                    items: [{ key: 'templates', rules: ['read'] }],
                }),
            };
        },
    },
    {
        links: ['main', '', undefined],
        name: 'joins-templates-main',
        level: 3,
        parentName: 'joins-templates',
    },
    {
        links: [],
        name: 'joins-templates-inner',
        contentOfLink: '+ Добавить шаблон',
        level: 3,
        parentName: 'joins-templates',
    },
    {
        links: ['main', '', undefined],
        name: 'joins-templates-inner-main',
        contentOfLink: 'Детали',
        level: 4,
        parentName: 'joins-templates-inner',
    },
    {
        links: ['contracts'],
        name: 'joins-contracts',
        contentOfLink: 'Договоры',
        level: 2,
        parentName: 'joins',
        getCond({ user }: StoreT<'crm'>): PageGetCondT {
            return {
                condition: getPermissions(user, {
                    key: 'joins',
                    items: [{ key: 'contracts', rules: ['read'] }],
                }),
            };
        },
    },
    {
        links: ['main', '', undefined],
        name: 'joins-contracts-main',
        level: 3,
        parentName: 'joins-contracts',
    },
    {
        links: ['docs'],
        name: 'joins-docs',
        contentOfLink: 'Документы',
        level: 2,
        parentName: 'joins',
        getCond({ user }: StoreT<'crm'>): PageGetCondT {
            return {
                condition: getPermissions(user, {
                    adminCorporation: true,
                    key: 'joins',
                    items: [{ key: 'docs', rules: ['read'] }],
                }),
            };
        },
    },
    {
        links: ['main', '', undefined],
        name: 'joins-docs-main',
        level: 3,
        parentName: 'joins-docs',
    },
    {
        links: [],
        name: 'joins-docs-inner',
        contentOfLink: '+ Добавить документ',
        level: 3,
        parentName: 'joins-docs',
    },
    {
        links: ['main', '', undefined],
        name: 'joins-docs-inner-main',
        contentOfLink: 'Детали',
        level: 4,
        parentName: 'joins-docs-inner',
    },
    {
        links: ['logs'],
        name: 'joins-docs-inner-logs',
        contentOfLink: 'Логи',
        level: 4,
        parentName: 'joins-docs-inner',
    },
    {
        links: ['mvd'],
        name: 'joins-mvd',
        contentOfLink: 'Уведомления МВД',
        level: 2,
        parentName: 'joins',
        getCond({ user }: StoreT<'crm'>): PageGetCondT {
            return {
                condition: getPermissions(user, {
                    key: 'joins',
                    items: [{ key: 'mvd', rules: ['read'] }],
                }),
            };
        },
    },
    {
        links: ['pays'],
        name: 'pays',
        contentOfLink: 'Выплаты',
        level: 1,
        parentName: 'corporation',
        getRedirect({ user }: StoreT<'crm'>): PageGetRedirectT {
            if (!user) {
                return { name: 'auth' };
            }

            return { name: 'corporation' };
        },
        getCond({ user }: StoreT<'crm'>): PageGetCondT {
            return {
                condition: getPermissions(user, {
                    key: 'pays',
                }),
            };
        },
    },
    {
        links: ['main'],
        name: 'pays-main',
        contentOfLink: 'Акты',
        level: 2,
        parentName: 'pays',
        getCond({ user }: StoreT<'crm'>): PageGetCondT {
            return {
                condition: getPermissions(user, {
                    key: 'pays',
                    items: [{ key: 'acts', rules: ['read'] }],
                }),
            };
        },
    },
    {
        links: [],
        name: 'pays-main-inner',
        level: 3,
        parentName: 'pays-main',
        getCond({ user }: StoreT<'crm'>): PageGetCondT {
            return {
                condition: getPermissions(user, {
                    key: 'pays',
                    items: [{ key: 'acts', rules: ['read'] }],
                }),
            };
        },
    },
    {
        links: ['groups'],
        name: 'pays-groups',
        contentOfLink: 'Реестр актов',
        level: 2,
        parentName: 'pays',
        getCond({ user }: StoreT<'crm'>): PageGetCondT {
            return {
                condition: getPermissions(user, {
                    key: 'pays',
                    items: [{ key: 'acts', rules: ['read'] }],
                }),
            };
        },
    },
    {
        links: ['main', '', undefined],
        name: 'pays-groups-main',

        level: 3,
        parentName: 'pays-groups',
    },
    {
        links: [],
        name: 'pays-groups-inner',
        level: 3,
        parentName: 'pays-groups',
    },
    {
        links: ['main', '', undefined],
        name: 'pays-groups-inner-main',
        contentOfLink: 'Детали',
        level: 4,
        parentName: 'pays-groups-inner',
    },
    {
        links: ['logs'],
        name: 'pays-groups-inner-logs',
        contentOfLink: 'Логи',
        level: 4,
        parentName: 'pays-groups-inner',
    },
    {
        links: ['tax'],
        name: 'pays-tax',
        contentOfLink: 'Налоговая копилка',

        level: 2,
        parentName: 'pays',
        getCond({ user }: StoreT<'crm'>): PageGetCondT {
            return {
                condition: getPermissions(user, {
                    key: 'pays',
                    items: [{ key: 'tax', rules: ['read'] }],
                }),
            };
        },
    },
    {
        links: ['limits'],
        name: 'pays-limits',
        contentOfLink: 'Лимиты',

        level: 2,
        parentName: 'pays',
        getCond({ user }: StoreT<'crm'>): PageGetCondT {
            return {
                condition: getPermissions(user, {
                    key: 'pays',
                    items: [{ key: 'limits', rules: ['read'] }],
                }),
            };
        },
    },

    {
        links: ['projects'],
        name: 'pays-projects',
        contentOfLink: 'Проекты',
        level: 2,
        parentName: 'pays',
        getCond({ user }: StoreT<'crm'>): PageGetCondT {
            return {
                condition: getPermissions(user, {
                    key: 'pays',
                    items: [{ key: 'projects', rules: ['read'] }],
                }),
            };
        },
    },
    {
        links: ['main', '', undefined],
        name: 'pays-projects-main',
        level: 3,
        parentName: 'pays-projects',
    },
    {
        links: [],
        name: 'pays-projects-inner',
        level: 3,
        parentName: 'pays-projects',
    },
    {
        links: ['main', '', undefined],
        name: 'pays-projects-inner-main',
        contentOfLink: 'Детали',
        level: 4,
        parentName: 'pays-projects-inner',
    },
    {
        links: ['logs'],
        name: 'pays-projects-inner-logs',
        contentOfLink: 'Логи',
        level: 4,
        parentName: 'pays-projects-inner',
    },
    {
        links: ['recruit'],
        name: 'recruit',
        contentOfLink: 'Найм',
        level: 1,
        parentName: 'corporation',
        getRedirect({ user }: StoreT<'crm'>): PageGetRedirectT {
            if (!user) {
                return { name: 'auth' };
            }

            return { name: 'corporation' };
        },
        getCond({ user }: StoreT<'crm'>): PageGetCondT {
            return {
                condition: getPermissions(user, {
                    key: 'recruit',
                }),
            };
        },
    },
    {
        links: ['dealGroup'],
        name: 'recruit-dealGroup',
        level: 2,
        parentName: 'recruit',
        isPopup: true,
        getCond({ user }: StoreT<'crm'>): PageGetCondT {
            return {
                condition: getPermissions(user, {
                    key: 'recruit',
                    items: [{ key: 'dealGroups', rules: ['read'] }],
                }),
            };
        },
        mainPage(): string {
            return 'recruit-dealGroups';
        },
    },
    {
        links: [],
        name: 'recruit-dealGroup-inner',
        level: 3,
        parentName: 'recruit-dealGroup',
    },
    {
        links: ['dealGroups'],
        name: 'recruit-dealGroups',
        contentOfLink: 'Воронки',
        level: 2,
        parentName: 'recruit',
        getCond({ user }: StoreT<'crm'>): PageGetCondT {
            return {
                condition: getPermissions(user, {
                    key: 'recruit',
                    items: [{ key: 'dealGroups', rules: ['read'] }],
                }),
            };
        },
    },
    {
        links: ['deals'],
        name: 'recruit-deals',
        contentOfLink: 'Сделки',
        level: 2,
        parentName: 'recruit',
        getCond({ user }: StoreT<'crm'>): PageGetCondT {
            return {
                condition: getPermissions(user, {
                    key: 'recruit',
                    items: [{ key: 'deals', rules: ['read'] }],
                }),
            };
        },
    },
    {
        links: [],
        name: 'recruit-deals-group',
        level: 3,
        parentName: 'recruit-deals',
    },
    {
        links: ['', undefined],
        name: 'recruit-deals-group-main',
        level: 4,
        parentName: 'recruit-deals-group',
    },
    {
        links: [],
        name: 'recruit-deals-group-inner',
        level: 4,
        parentName: 'recruit-deals-group',
    },
    {
        links: ['', undefined],
        name: 'recruit-deals-group-inner-main',
        level: 5,
        parentName: 'recruit-deals-group-inner',
    },
    {
        links: ['calls'],
        name: 'recruit-calls',
        contentOfLink: 'Звонки',
        level: 2,
        parentName: 'recruit',
        getCond({ user }: StoreT<'crm'>): PageGetCondT {
            return {
                condition: getPermissions(user, {
                    key: 'recruit',
                    items: [{ key: 'calls', rules: ['read'] }],
                }),
            };
        },
    },
    {
        links: ['tasks'],
        name: 'tasks',
        contentOfLink: 'Задачи',
        level: 1,
        parentName: 'corporation',
        getRedirect({ user }: StoreT<'crm'>): PageGetRedirectT {
            if (!user) {
                return { name: 'auth' };
            }

            return { name: 'corporation' };
        },
        getCond({ user }: StoreT<'crm'>): PageGetCondT {
            return {
                condition: getPermissions(user, { key: 'tasks' }),
            };
        },
    },
    {
        links: [undefined, ''],
        name: 'tasks-main',
        level: 2,
        parentName: 'tasks',
        getCond({ user }: StoreT<'crm'>): PageGetCondT {
            return {
                condition: getPermissions(user, {
                    key: 'tasks',
                    items: [{ key: 'main', rules: ['read'] }],
                }),
            };
        },
    },
    {
        links: [],
        name: 'tasks-inner',
        level: 2,
        parentName: 'tasks',
        getCond({ user }: StoreT<'crm'>): PageGetCondT {
            return {
                condition: getPermissions(user, {
                    key: 'tasks',
                    items: [{ key: 'main', rules: ['read'] }],
                }),
            };
        },
    },
    {
        links: [undefined, ''],
        name: 'tasks-inner-main',
        contentOfLink: 'Детали',
        level: 3,
        parentName: 'tasks-inner',
    },
    {
        links: ['logs'],
        name: 'tasks-inner-logs',
        contentOfLink: 'Логи',
        level: 3,
        parentName: 'tasks-inner',
    },
    {
        links: ['manual'],
        name: 'manual',
        contentOfLink: 'Справочник',
        level: 1,
        parentName: 'corporation',
        getRedirect({ user }: StoreT<'crm'>): PageGetRedirectT {
            if (!user) {
                return { name: 'auth' };
            }

            return { name: 'corporation' };
        },
        getCond({ user }: StoreT<'crm'>): PageGetCondT {
            return {
                condition: getPermissions(user, {
                    key: 'manual',
                }),
            };
        },
    },
    {
        links: ['executors'],
        name: 'manual-executors',
        contentOfLink: 'Исполнители',
        level: 2,
        parentName: 'manual',
        getCond({ user }: StoreT<'crm'>): PageGetCondT {
            return {
                condition: getPermissions(user, {
                    key: 'manual',
                    items: [{ key: 'executors', rules: ['read'] }],
                }),
            };
        },
    },
    {
        links: ['main', '', undefined],
        name: 'manual-executors-main',

        level: 3,
        parentName: 'manual-executors',
    },
    {
        links: [],
        name: 'manual-executors-inner',
        contentOfLink: '+ Добавить исполнителя',
        level: 3,
        parentName: 'manual-executors',
    },
    {
        links: ['main', '', undefined],
        name: 'manual-executors-inner-main',
        contentOfLink: 'Детали',
        level: 4,
        parentName: 'manual-executors-inner',
    },
    {
        links: ['docs'],
        name: 'manual-executors-inner-docs',
        contentOfLink: 'Документы',
        level: 4,
        parentName: 'manual-executors-inner',
    },
    {
        links: ['wallets'],
        name: 'manual-executors-inner-wallets',
        contentOfLink: 'Реквизиты',
        level: 4,
        parentName: 'manual-executors-inner',
    },
    {
        links: ['pays'],
        name: 'manual-executors-inner-pays',
        contentOfLink: 'Выплаты',
        level: 4,
        parentName: 'manual-executors-inner',
    },
    {
        links: ['balance'],
        name: 'manual-executors-inner-balance',
        contentOfLink: 'Баланс',
        level: 4,
        parentName: 'manual-executors-inner',
    },
    {
        links: ['contracts'],
        name: 'manual-executors-inner-contracts',
        contentOfLink: 'Договоры',
        level: 4,
        parentName: 'manual-executors-inner',
    },
    {
        links: ['logs'],
        name: 'manual-executors-inner-logs',
        contentOfLink: 'Логи',
        level: 4,
        parentName: 'manual-executors-inner',
    },
    {
        links: ['tags'],
        name: 'manual-tags',
        contentOfLink: 'Теги',
        level: 2,
        parentName: 'manual',
        getCond({ user }: StoreT<'crm'>): PageGetCondT {
            return {
                condition: getPermissions(user, {
                    key: 'manual',
                    items: [{ key: 'tags', rules: ['read'] }],
                }),
            };
        },
    },
    {
        links: ['main', '', undefined],
        name: 'manual-tags-main',
        level: 3,
        parentName: 'manual-tags',
    },
    {
        links: [],
        name: 'manual-tags-inner',
        contentOfLink: '+ Добавить тег',
        level: 3,
        parentName: 'manual-tags',
    },
    {
        links: ['main', undefined, ''],
        name: 'manual-tags-inner-main',
        contentOfLink: 'Детали',
        level: 4,
        parentName: 'manual-tags-inner',
    },
    {
        links: ['logs'],
        name: 'manual-tags-inner-logs',
        contentOfLink: 'Логи',
        level: 4,
        parentName: 'manual-tags-inner',
        getRedirect() {
            return {
                name: 'auth',
            };
        },
        getCond({ user }: StoreT<'crm'>): PageGetCondT {
            return {
                condition: !!(user || getCookie(process.env.REACT_APP_HASH)),
            };
        },
    },
    {
        links: ['docs'],
        name: 'manual-docs',
        contentOfLink: 'Документы',
        level: 2,
        parentName: 'manual',
        getCond({ user }: StoreT<'crm'>): PageGetCondT {
            return {
                condition: getPermissions(user, {
                    adminCorporation: true,
                    key: 'manual',
                    items: [{ key: 'docs', rules: ['read'] }],
                }),
            };
        },
    },
    {
        links: ['cities'],
        name: 'manual-cities',
        contentOfLink: 'Города',
        level: 2,
        parentName: 'manual',
        getCond({ user }: StoreT<'crm'>): PageGetCondT {
            return {
                condition: getPermissions(user, {
                    adminCorporation: true,
                    key: 'manual',
                    items: [{ key: 'cities', rules: ['read'] }],
                }),
            };
        },
    },
    {
        links: ['main', '', undefined],
        name: 'manual-cities-main',
        level: 3,
        parentName: 'manual-cities',
    },
    {
        links: [],
        name: 'manual-cities-inner',
        contentOfLink: '+ Добавить тег',
        level: 3,
        parentName: 'manual-cities',
    },
    {
        links: ['main', undefined, ''],
        name: 'manual-cities-inner-main',
        contentOfLink: 'Детали',
        level: 4,
        parentName: 'manual-cities-inner',
    },
    {
        links: ['logs'],
        name: 'manual-cities-inner-logs',
        contentOfLink: 'Логи',
        level: 4,
        parentName: 'manual-cities-inner',
    },

    {
        links: ['countries'],
        name: 'manual-countries',
        contentOfLink: 'Страны',
        level: 2,
        parentName: 'manual',
        getCond({ user }: StoreT<'crm'>): PageGetCondT {
            return {
                condition: getPermissions(user, {
                    adminCorporation: true,
                    key: 'manual',
                    items: [{ key: 'countries', rules: ['read'] }],
                }),
            };
        },
    },
    {
        links: ['main', '', undefined],
        name: 'manual-countries-main',
        level: 3,
        parentName: 'manual-countries',
    },
    {
        links: [],
        name: 'manual-countries-inner',
        contentOfLink: '+ Добавить тег',
        level: 3,
        parentName: 'manual-countries',
    },
    {
        links: ['main', undefined, ''],
        name: 'manual-countries-inner-main',
        contentOfLink: 'Детали',
        level: 4,
        parentName: 'manual-countries-inner',
    },
    {
        links: ['logs'],
        name: 'manual-countries-inner-logs',
        contentOfLink: 'Логи',
        level: 4,
        parentName: 'manual-countries-inner',
    },

    {
        links: ['executorTypes'],
        name: 'manual-executorTypes',
        contentOfLink: 'Категории исполнителей',
        level: 2,
        parentName: 'manual',
        getCond({ user }: StoreT<'crm'>): PageGetCondT {
            return {
                condition: getPermissions(user, {
                    adminCorporation: true,
                    key: 'manual',
                    items: [{ key: 'executorTypes', rules: ['read'] }],
                }),
            };
        },
    },
    {
        links: ['main', '', undefined],
        name: 'manual-executorTypes-main',
        level: 3,
        parentName: 'manual-executorTypes',
    },
    {
        links: [],
        name: 'manual-executorTypes-inner',
        level: 3,
        parentName: 'manual-executorTypes',
    },
    {
        links: ['main', undefined, ''],
        name: 'manual-executorTypes-inner-main',
        contentOfLink: 'Детали',
        level: 4,
        parentName: 'manual-executorTypes-inner',
    },
    {
        links: ['logs'],
        name: 'manual-executorTypes-inner-logs',
        contentOfLink: 'Логи',
        level: 4,
        parentName: 'manual-executorTypes-inner',
    },

    {
        links: ['auth'],
        name: 'auth',
        isPopup: true,
        getRedirect() {
            return { name: 'corporation' };
        },
        getCond({ user, isProccessLogin, isProccessLogout }: StoreT<'crm'>): PageGetCondT {
            return {
                condition:
                    (!user && !getCookie(process.env.REACT_APP_HASH)) ||
                    isProccessLogin ||
                    isProccessLogout,
            };
        },
        contentOfLink: 'Главная',
    },
    {
        links: ['', undefined, 'login'],
        name: 'login',
        level: 1,
        parentName: 'auth',
    },
    {
        links: ['registration'],
        name: 'registration',
        level: 1,
        parentName: 'auth',
    },
    {
        links: ['forget'],
        name: 'forget',
        level: 1,
        parentName: 'auth',
    },
    {
        links: ['chat'],
        name: 'chat',
        contentOfLink: 'Чаты',
        level: 1,
        parentName: 'corporation',
        getRedirect({ user }: StoreT<'crm'>): PageGetRedirectT {
            if (!user) {
                return { name: 'auth' };
            }

            return { name: 'corporation' };
        },
        getCond({ user }: StoreT<'crm'>): PageGetCondT {
            return {
                condition: getPermissions(user, {
                    key: 'chat',
                }),
            };
        },
    },
    {
        links: [],
        name: 'chat-inner',
        contentOfLink: '',
        level: 2,
        parentName: 'chat',
    },
    {
        links: ['chat-templates'],
        name: 'chat-templates',
        contentOfLink: 'Шаблоны',
        level: 1,
        parentName: 'corporation',
        group: 'chat',
        getCond({ user }: StoreT<'crm'>): PageGetCondT {
            return {
                condition: getPermissions(user, {
                    key: 'chat',
                    items: [{ key: 'templates', rules: ['read'] }],
                }),
            };
        },
    },
    {
        links: ['profile'],
        name: 'profile',
        group: 'cabinet',
    },
    {
        links: ['', undefined, 'main'],
        name: 'profile-main',
        contentOfLink: 'Детали',
        level: 1,
        parentName: 'profile',
    },
    {
        links: ['proxy'],
        name: 'profile-proxy',
        contentOfLink: 'Доверенности',
        level: 1,
        parentName: 'profile',
        getCond({ user }: StoreT<'crm'>): PageGetCondT {
            return {
                condition: user?.idOfCurrentCorporation !== 'admin',
            };
        },
    },
    {
        links: ['content'],
        name: 'content',
        level: 1,
        parentName: 'corporation',
        contentOfLink: 'Контент',
        getCond({ user }: StoreT<'crm'>): PageGetCondT {
            return {
                condition:
                    checkAdminCorporation({ user }) &&
                    getPermissions(user, {
                        key: 'content',
                    }),
            };
        },
    },
    {
        links: ['blog'],
        name: 'content-blog',
        contentOfLink: 'Блог',
        getCond({ user }: StoreT<'crm'>): PageGetCondT {
            return {
                condition:
                    checkAdminCorporation({ user }) &&
                    getPermissions(user, {
                        key: 'content',
                        items: [{ key: 'blog', rules: ['read'] }],
                    }),
            };
        },
        level: 2,
        parentName: 'content',
    },
    {
        links: ['main', '', undefined],
        name: 'content-blog-main',
        level: 3,
        parentName: 'content-blog',
    },
    {
        links: [],
        name: 'content-blog-inner',
        contentOfLink: '+ Добавить статью',
        level: 3,
        parentName: 'content-blog',
    },
    {
        links: ['', undefined],
        name: 'content-blog-inner-main',
        contentOfLink: 'Детали',
        level: 4,
        parentName: 'content-blog-inner',
    },
    {
        links: ['logs'],
        name: 'content-blog-inner-logs',
        contentOfLink: 'Логи',
        level: 4,
        parentName: 'content-blog-inner',
    },
    {
        links: ['announce'],
        name: 'content-announce',
        contentOfLink: 'Анонсы',
        getCond({ user }: StoreT<'crm'>): PageGetCondT {
            return {
                condition:
                    checkAdminCorporation({ user }) &&
                    getPermissions(user, {
                        key: 'content',
                        items: [{ key: 'announce', rules: ['read'] }],
                    }),
            };
        },
        level: 2,
        parentName: 'content',
    },
    {
        links: ['main', '', undefined],
        name: 'content-announce-main',
        level: 3,
        parentName: 'content-announce',
    },
    {
        links: [],
        name: 'content-announce-inner',
        contentOfLink: '+ Добавить статью',
        level: 3,
        parentName: 'content-announce',
    },
    {
        links: ['', undefined],
        name: 'content-announce-inner-main',
        contentOfLink: 'Детали',
        level: 4,
        parentName: 'content-announce-inner',
    },
    {
        links: ['logs'],
        name: 'content-announce-inner-logs',
        contentOfLink: 'Логи',
        level: 4,
        parentName: 'content-announce-inner',
    },
    {
        links: ['legal'],
        name: 'content-legal',
        contentOfLink: 'Правовая информация',
        getCond({ user }: StoreT<'crm'>): PageGetCondT {
            return {
                condition:
                    checkAdminCorporation({ user }) &&
                    getPermissions(user, {
                        key: 'content',
                        items: [{ key: 'legal', rules: ['read'] }],
                    }),
            };
        },
        level: 2,
        parentName: 'content',
    },
    {
        links: ['main', '', undefined],
        name: 'content-legal-main',
        level: 3,
        parentName: 'content-legal',
    },
    {
        links: [],
        name: 'content-legal-inner',
        contentOfLink: '+ Добавить статью',
        level: 3,
        parentName: 'content-legal',
    },
    {
        links: ['', undefined],
        name: 'content-legal-inner-main',
        contentOfLink: 'Детали',
        level: 4,
        parentName: 'content-legal-inner',
    },
    {
        links: ['content'],
        name: 'content-legal-inner-logs',
        contentOfLink: 'Логи',
        level: 4,
        parentName: 'content-legal-inner',
    },

    {
        links: ['users-knowledge'],
        name: 'content-users-knowledge',
        contentOfLink: 'База знаний сотрудников',
        getCond({ user }: StoreT<'crm'>): PageGetCondT {
            return {
                condition: getPermissions(user, {
                    adminCorporation: true,
                    key: 'content',
                    items: [{ key: 'knowledge', rules: ['read'] }],
                }),
            };
        },
        level: 2,
        parentName: 'content',
    },
    {
        links: ['main', '', undefined],
        name: 'content-users-knowledge-main',
        level: 3,
        parentName: 'content-users-knowledge',
    },
    {
        links: [],
        name: 'content-users-knowledge-inner',
        contentOfLink: '+ Добавить статью',
        level: 3,
        parentName: 'content-users-knowledge',
    },
    {
        links: ['', undefined],
        name: 'content-users-knowledge-inner-main',
        contentOfLink: 'Детали',
        level: 4,
        parentName: 'content-users-knowledge-inner',
    },
    {
        links: ['logs'],
        name: 'content-users-knowledge-inner-logs',
        contentOfLink: 'Логи',
        level: 4,
        parentName: 'content-users-knowledge-inner',
    },

    {
        links: ['clients-knowledge'],
        name: 'content-clients-knowledge',
        contentOfLink: 'База знаний клиентов',
        getCond({ user }: StoreT<'crm'>): PageGetCondT {
            return {
                condition: getPermissions(user, {
                    adminCorporation: true,
                    key: 'content',
                    items: [{ key: 'knowledge', rules: ['read'] }],
                }),
            };
        },
        level: 2,
        parentName: 'content',
    },
    {
        links: ['main', '', undefined],
        name: 'content-clients-knowledge-main',
        level: 3,
        parentName: 'content-clients-knowledge',
    },
    {
        links: [],
        name: 'content-clients-knowledge-inner',
        contentOfLink: '+ Добавить статью',
        level: 3,
        parentName: 'content-clients-knowledge',
    },
    {
        links: ['', undefined],
        name: 'content-clients-knowledge-inner-main',
        contentOfLink: 'Детали',
        level: 4,
        parentName: 'content-clients-knowledge-inner',
    },
    {
        links: ['logs'],
        name: 'content-clients-knowledge-inner-logs',
        contentOfLink: 'Логи',
        level: 4,
        parentName: 'content-clients-knowledge-inner',
    },

    {
        links: ['settings'],
        name: 'settings',
        level: 1,
        parentName: 'corporation',
        getCond({ user }: StoreT<'crm'>): PageGetCondT {
            return {
                condition: getPermissions(user, {
                    key: 'settings',
                }),
            };
        },
    },
    {
        links: ['', undefined, 'main'],
        name: 'settings-main',
        contentOfLink: 'Данные компании',
        level: 2,
        parentName: 'settings',
        getCond({ user }: StoreT<'crm'>): PageGetCondT {
            return {
                condition: getPermissions(user, {
                    key: 'settings',
                    items: [{ key: 'main', rules: ['read'] }],
                }),
            };
        },
    },
    {
        links: ['operations'],
        name: 'settings-operations',
        contentOfLink: 'Операции',
        level: 2,
        parentName: 'settings',
        getCond({ user }: StoreT<'crm'>): PageGetCondT {
            return {
                condition: getPermissions(user, {
                    key: 'settings',
                    items: [{ key: 'balance', rules: ['read'] }],
                }),
            };
        },
    },
    {
        links: ['docs'],
        name: 'settings-docs',
        contentOfLink: 'Документы',
        level: 2,
        parentName: 'settings',
        getCond({ user }: StoreT<'crm'>): PageGetCondT {
            return {
                condition:
                    user?.idOfCurrentCorporation !== 'admin' &&
                    getPermissions(user, {
                        key: 'settings',
                        items: [{ key: 'main', rules: ['read'] }],
                    }),
            };
        },
    },
    {
        links: ['users'],
        name: 'settings-users',
        contentOfLink: 'Сотрудники',
        level: 2,
        parentName: 'settings',
        getCond({ user }: StoreT<'crm'>): PageGetCondT {
            return {
                condition: getPermissions(user, {
                    key: 'settings',
                    items: [{ key: 'users', rules: ['read'] }],
                }),
            };
        },
    },
    {
        links: ['roles'],
        name: 'settings-roles',
        contentOfLink: 'Роли',
        level: 2,
        parentName: 'settings',
        getCond({ user }: StoreT<'crm'>): PageGetCondT {
            return {
                condition: getCurrentCorporation({ user, isInfo: false })?.role === 'holder',
            };
        },
    },
    {
        links: ['', undefined, 'main'],
        name: 'settings-roles-main',
        level: 3,
        parentName: 'settings-roles',
    },
    {
        links: [],
        name: 'settings-roles-inner',
        level: 3,
        parentName: 'settings-roles',
    },
    {
        links: ['proxy'],
        name: 'settings-proxy',
        contentOfLink: 'Доверенности',
        level: 2,
        parentName: 'settings',
        getCond({ user }: StoreT<'crm'>): PageGetCondT {
            return {
                condition: getCurrentCorporation({ user, isInfo: false })?.role === 'holder',
            };
        },
    },
    {
        links: ['integrations'],
        name: 'settings-integrations',
        contentOfLink: 'Интеграции',
        level: 2,
        parentName: 'settings',
        getCond({ user }: StoreT<'crm'>): PageGetCondT {
            return {
                condition: getPermissions(user, {
                    key: 'settings',
                    items: [{ key: 'integrations', rules: ['read'] }],
                }),
            };
        },
    },
    {
        links: [undefined, ''],
        name: 'settings-integrations-main',
        level: 3,
        parentName: 'settings-integrations',
    },
    {
        links: [],
        name: 'settings-integrations-inner',
        level: 3,
        parentName: 'settings-integrations',
    },
    {
        links: ['reports'],
        name: 'settings-reports',
        contentOfLink: 'Отчёты',
        level: 2,
        parentName: 'settings',
        getCond({ user }: StoreT<'crm'>): PageGetCondT {
            return {
                condition: getPermissions(user, {
                    key: 'settings',
                    items: [{ key: 'reports', rules: ['read'] }],
                }),
            };
        },
    },
    {
        links: ['incomes'],
        name: 'settings-incomes',
        contentOfLink: 'Доходы',
        level: 2,
        parentName: 'settings',
        getCond({ user }: StoreT<'crm'>): PageGetCondT {
            return {
                condition: getPermissions(user, {
                    key: 'settings',
                    items: [{ key: 'incomes', rules: ['read'] }],
                }),
            };
        },
    },
    {
        links: ['logs'],
        name: 'settings-logs',
        contentOfLink: 'Логи',
        level: 2,
        parentName: 'settings',
        getCond({ user }: StoreT<'crm'>): PageGetCondT {
            return {
                condition: getPermissions(user, {
                    key: 'settings',
                    items: [{ key: 'logs', rules: ['read'] }],
                }),
            };
        },
    },
    {
        links: ['pdf'],
        name: 'pdf',
    },
    {
        links: ['pep'],
        name: 'pep',
        isPopup: true,
        mainPage({ user }: StoreT<'crm'>): string {
            return user ? 'corporation' : 'public';
        },
    },
    {
        links: ['users-knowledge'],
        name: 'users-knowledge',
        isPopup: true,
        mainPage(): string {
            return 'corporation';
        },
        getCond({ user }: StoreT<'crm'>): PageGetCondT {
            return {
                condition: user?.idOfCurrentCorporation === 'admin',
            };
        },
    },
    {
        links: [],
        name: 'users-knowledge-inner',
        level: 1,
        parentName: 'users-knowledge',
    },
    {
        links: ['clients-knowledge'],
        name: 'clients-knowledge',
        isPopup: true,
        mainPage({ user }: StoreT<'crm'>): string {
            return user ? 'corporation' : 'public';
        },
    },
    {
        links: [],
        name: 'clients-knowledge-inner',
        level: 1,
        parentName: 'clients-knowledge',
    },
    {
        links: ['', undefined],
        name: 'public',
        group: 'public',
    },
    {
        links: ['mailings'],
        name: 'mailings',
        level: 1,
        parentName: 'corporation',
        group: 'chat',
        contentOfLink: 'Рассылки',
        getCond({ user }: StoreT<'crm'>): PageGetCondT {
            return {
                condition: getPermissions(user, {
                    key: 'chat',
                    items: [{ key: 'mailing', rules: ['read'] }],
                }),
            };
        },
    },
    {
        links: ['business'],
        name: 'public-business',
        contentOfLink: 'Решения для бизнеса',
        level: 1,
        parentName: 'public',
    },
    {
        links: ['executors', undefined],
        name: 'public-executors',
        contentOfLink: 'Исполнителям',
        level: 1,
        parentName: 'public',
    },
    {
        links: ['blog'],
        name: 'public-blog',
        contentOfLink: 'Блог',
        level: 1,
        parentName: 'public',
    },
    {
        links: [],
        name: 'public-blog-inner',
        contentOfLink: 'Блог',
        level: 2,
        parentName: 'public-blog',
    },
    {
        links: ['legal'],
        name: 'public-legal',
        contentOfLink: 'Правовая информация',
        level: 1,
        parentName: 'public',
    },
    {
        links: [],
        name: 'public-legal-inner',
        level: 2,
        parentName: 'public-legal',
    },
] as const;

export default crmPages;
